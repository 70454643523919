@define-mixin resetButtonStyles {
    @mixin p30;
    color: var(--textColor);
    font-weight: 400;
    text-align: initial;
    cursor: pointer;
    display: block;
    background: none;
    padding: 0;
    border: none;
    border-radius: 0;
}

@define-mixin buttonBase {
    @mixin p30;
    @mixin centerElements;

    @mixin onFocus {
        @mixin focusVisible;
    }
    font-weight: 600;
    cursor: pointer;
    padding: 8px 32px;

    &:disabled,
    &:disabled:hover {
        cursor: default;
    }

    & :global(.SvgIcon) {
        @mixin fixedRatioSvgIcon 24px;

        &:first-child {
            margin-right: 12px;
        }

        &:last-child {
            margin-left: 12px;
        }
    }
}

@define-mixin button $color, $hoverColor, $textColor {
    @mixin buttonBase;
    @mixin buttonTransitionBehavior single-arg(background-color, opacity);
    color: $textColor;
    background-color: $color;
    border: none;

    &:hover {
        background-color: $hoverColor;
    }

    &:disabled,
    &:disabled:hover {
        opacity: 0.4;
        background-color: $color;
    }
}

@define-mixin primaryButton {
    @mixin button var(--blackLighten10Color), var(--blackLighten20Color), var(--whiteBaseColor);
    @mixin p30;

    &:active {
        color: var(--whiteBaseColor);
    }

    & :global(.SvgIcon) {
        @mixin fixedRatioSvgIcon 24px;

        &:first-child {
            margin-right: 12px;
        }

        &:last-child {
            margin-left: 12px;
        }
    }
}

@define-mixin secondaryButton {
    @mixin outlineButton var(--blackLighten10Color), var(--blackLighten50Color);
    @mixin p30;

    & :global(.SvgIcon) {
        @mixin fixedRatioSvgIcon 24px;

        &:first-child {
            margin-right: 12px;
        }

        &:last-child {
            margin-left: 12px;
        }
    }
}

@define-mixin outlineButton $color, $hoverBgColor, $borderColor: $color {
    @mixin buttonBase;
    @mixin buttonTransitionBehavior single-arg(background-color, opacity);
    color: $color;
    background-color: transparent;
    border: 2px solid $borderColor;

    &:hover {
        background-color: $hoverBgColor;
    }

    &:disabled,
    &:disabled:hover {
        opacity: 0.4;
        background-color: transparent;
    }
}

@define-mixin xSmallButton {
    &.Variant--solid {
        padding: 4px 8px;
    }

    &.Variant--outline {
        padding: 2px 8px;
    }

    &.Variant--solid,
    &.Variant--outline {
        @mixin p50;
        padding: 4px 8px;
    }
}

@define-mixin smallButton {
    &.Variant--solid {
        padding: 6px 24px;
    }

    &.Variant--outline {
        padding: 4px 24px;
    }

    &.Variant--solid,
    &.Variant--outline {
        @mixin p40;
    }
}

@define-mixin largeButton {
    &.Variant--solid {
        padding: 12px 48px;
    }

    &.Variant--outline {
        padding: 10px 48px;
    }

    &.Variant--solid,
    &.Variant--outline {
        @mixin p30;

        & :global(.SvgIcon) {
            width: 24px;
            height: 24px;
        }
    }
}

@define-mixin xLargeButton {
    &.Variant--solid {
        padding: 16px 64px;
    }

    &.Variant--outline {
        padding: 14px 64px;
    }

    &.Variant--solid,
    &.Variant--outline {
        @mixin h50;

        & :global(.SvgIcon) {
            width: 24px;
            height: 24px;
        }
    }
}

@define-mixin closeButtonBase $topPosition, $iconSize, $color, $z-index {
    @mixin onFocus {
        @mixin focusVisible;
    }
    position: absolute;
    top: $topPosition;
    color: $color;
    z-index: $z-index;

    & :global(.SvgIcon) {
        @mixin fixedRatioSvgIcon $iconSize;
    }
}

@define-mixin rightCloseButton $topPosition, $rightPosition, $iconSize, $color: currentColor, $z-index: 10 {
    @mixin closeButtonBase $topPosition, $iconSize, $color, $z-index;
    right: $rightPosition;
}

@define-mixin leftCloseButton $topPosition, $leftPosition, $iconSize, $color: currentColor, $z-index: 10 {
    @mixin closeButtonBase $topPosition, $iconSize, $color, $z-index;
    left: $leftPosition;
}

@define-mixin linkButton $svgIconSize: 20px, $borderBottomColor: var(--primaryHKBaseColor),
    $borderBottomColorOnHover: var(--primaryHKLighten40Color) {
    @mixin centerElements;

    @mixin onFocus {
        @mixin focusVisible;
    }
    gap: 8px;

    &:hover {
        & > span:not(:global(.SvgIcon)) {
            border-bottom-color: $borderBottomColorOnHover;
        }
    }

    & > span:not(:global(.SvgIcon)) {
        @mixin p30;
        border-bottom: 2px solid $borderBottomColor;
    }

    & :global(.SvgIcon) {
        @mixin fixedRatioSvgIcon $svgIconSize;
    }
}

@define-mixin campusApplicationDocumentsButtonBase {
    @mixin p30;
    @mixin centerElements;

    @mixin onFocus {
        @mixin focusVisible;
    }
    padding: 16px 24px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    @media (--tablet) {
        @mixin p40;
        padding: 8px 24px;
    }

    &:disabled,
    &:disabled:hover {
        cursor: default;
    }
}

@define-mixin campusApplicationDocumentsButtonVariant $backgroundColor, $hoverBgColor, $textColor,
    $borderColor: $backgroundColor, $hoverBorderColor: $hoverBgColor {
    @mixin buttonTransitionBehavior single-arg(background-color, opacity, border-color);
    color: $textColor;
    background-color: $backgroundColor;
    border: 2px solid $borderColor;

    &:hover {
        background-color: $hoverBgColor;
        border-color: $hoverBorderColor;
    }

    &:disabled,
    &:disabled:hover {
        opacity: 0.4;
        background-color: $backgroundColor;
        border-color: $borderColor;
    }
}

@define-mixin campusApplicationDocumentsButtonVariantSolid {
    @mixin campusApplicationDocumentsButtonVariant var(--actionBlueBaseColor), var(--actionBlueLighten20Color),
        var(--whiteBaseColor);
}

@define-mixin campusApplicationDocumentsButtonVariantOutline {
    @mixin campusApplicationDocumentsButtonVariant transparent, var(--actionBlueLighten40Color),
        var(--actionBlueBaseColor), var(--actionBlueBaseColor), var(--actionBlueBaseColor);
}
