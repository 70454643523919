/* LEGACY HEADINGS */
@define-mixin h1 {
    font-size: 56px;
    line-height: 1.29;
    margin: 0 0 32px;
}

@define-mixin h2 {
    font-size: 32px;
    line-height: 1.38;
    margin: 0 0 32px;
}

@define-mixin h3 {
    font-size: 24px;
    line-height: 1.67;
}

@define-mixin h4 {
    font-size: 20px;
    line-height: 1.4;
}

@define-mixin h5 {
    font-size: 18px;
    line-height: 1.44;
}

@define-mixin p {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 24px;
}

@define-mixin label {
    font-size: 14px;
    line-height: 1.43;
    font-weight: 500;
}

@define-mixin caption {
    font-size: 12px;
    line-height: 1.33;
}

/* END OF LEGACY HEADINGS */

@define-mixin h00 {
    font-size: 88px;
    line-height: 104px;
}

@define-mixin h10 {
    font-size: 64px;
    line-height: 88px;
}

@define-mixin h20 {
    font-size: 48px;
    line-height: 64px;
}

@define-mixin h30 {
    font-size: 32px;
    line-height: 48px;
}

@define-mixin h40 {
    font-size: 24px;
    line-height: 36px;
}

@define-mixin h50 {
    font-size: 20px;
    line-height: 32px;
}

@define-mixin p10 {
    font-size: 20px;
    line-height: 32px;
}

@define-mixin p20 {
    font-size: 18px;
    line-height: 26px;
}

@define-mixin p30 {
    font-size: 16px;
    line-height: 24px;
}

@define-mixin p40 {
    font-size: 14px;
    line-height: 20px;
}

@define-mixin p50 {
    font-size: 12px;
    line-height: 16px;
}

@define-mixin p60 {
    font-size: 8px;
    line-height: 16px;
}
