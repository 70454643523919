@define-mixin visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

@define-mixin linkOverlay {
    position: relative;

    &:hover {
        & img {
            filter: opacity(0.8);
        }
    }

    & img {
        transition: 0.2s ease;
    }

    & a[class*='__linkOverlay'] {
        @mixin onFocus {
            @mixin focusVisible 0;
            outline-width: 1px;
        }
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

@define-mixin aspectRatio $ratio {
    position: relative;

    &::after {
        display: block;
        padding-bottom: calc(100% * $ratio);
        content: '';
    }

    & > * {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    & img {
        object-fit: cover;
    }
}

@define-mixin lineClamp $line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
}

@define-mixin singleLineClamp {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@define-mixin skipLink {
    position: fixed;
    width: 1px;
    height: 1px;
    padding: 8px 16px;
    overflow: hidden;
    left: 16px;
    top: 20px;
    background: transparent;
    clip: rect(0 0 0 0);
    border: none;

    &:focus {
        background: var(--redBaseLegacyColor);
        width: auto;
        height: auto;
        color: var(--whiteBaseColor);
        z-index: 1090;
        clip: auto;
    }
}

@define-mixin fillParent {
    height: 100%;
    width: 100%;
}

@define-mixin absoluteParentSize {
    @mixin fillParent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@define-mixin svgIconColor $color {
    & svg {
        fill: $color;
    }
}

@define-mixin svgIcon $width, $height, $fill: currentColor {
    @mixin svgIconColor $fill;
    position: relative;
    width: $width;
    height: $height;
    display: block;
    flex-shrink: 0;

    & svg {
        @mixin absoluteParentSize;
    }
}

@define-mixin fixedRatioSvgIcon $size, $fill: currentColor {
    @mixin svgIcon $size, $size, $fill;
}

@define-mixin scalableSvgIcon $refWidth, $refHeight {
    @mixin svgIcon 100%, auto;
    aspect-ratio: $refWidth / $refHeight;
    max-width: min(calc($refWidth * 1px), 100%);
}

@define-mixin fullHeightReactChild {
    & div[id^='react_'] {
        height: 100%;
    }
}

@define-mixin onFocus {
    &:global(.focus-visible):focus,
    &:focus-visible {
        @mixin-content;
    }
}

@define-mixin lightFocusVisible $outlineOffset: 1px {
    outline: 4px solid var(--notificationFocusLighten60Color);
    outline-offset: $outlineOffset;
}

@define-mixin focusVisible $outlineOffset: 1px {
    outline: 4px solid var(--notificationFocusBaseColor);
    outline-offset: $outlineOffset;
}

@define-mixin hyphenateWords $value: auto {
    /* Fallback for capitalized letters. Ref: https://github.com/mozilla/wg-decisions/issues/778 */
    overflow-wrap: anywhere;
    word-break: normal;
    hyphens: $value;
}

@define-mixin centerElements {
    display: flex;
    align-items: center;
    justify-content: center;
}

@define-mixin centerItself {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@define-mixin stickToTop $top: 0 {
    position: sticky;
    top: $top;
}

@define-mixin stickToBottom $bottom: 0 {
    position: sticky;
    bottom: $bottom;
}

@define-mixin withFullScreenUnderline $height: 1px, $color: var(--blackLighten20Color) {
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: $height;
        width: 100vw;
        background: $color;
    }
}

@define-mixin listWithSeparator $separator: '/' {
    @mixin clearList;
    display: flex;
    flex-wrap: wrap;

    & li {
        @mixin separators $separator;
    }
}

@define-mixin separators $separator: '/', $gap: 8px {
    &:not(:last-child)::after {
        content: $separator;
        margin: 0 $gap;
    }
}

@define-mixin separateEveryChild $separator: '/', $gap: 8px {
    & > *:not(:last-child)::after {
        content: $separator;
        margin: 0 $gap;
    }
}

@define-mixin autoScrollY {
    overflow: hidden auto;
}

@define-mixin stretchContent {
    height: 100%;
    display: flex;
    flex-direction: column;
}

@define-mixin stackChildren {
    display: grid;
    grid-auto-rows: 0;
}
