.Variant--solid {
    &.Variant--primary {
        @mixin button var(--blackLighten10Color), var(--blackLighten20Color), var(--whiteBaseColor);
    }

    &.Variant--secondary {
        @mixin button var(--support01HKDarken20Color), var(--support01HKBaseColor), var(--blackLighten10Color);
    }

    &.Variant--tertiary {
        @mixin button var(--whiteBaseColor), var(--blackLighten40Color), var(--blackLighten10Color);
    }

    &.Variant--red {
        @mixin button var(--primaryHKBaseColor), var(--primaryHKLighten20Color), var(--whiteBaseColor);
    }

    &.Variant--legacyPrimary {
        @mixin button var(--support03HKBaseColor), var(--support03HKLighten20Color), var(--whiteBaseColor);
    }
}

.Variant--outline {
    &.Variant--primary {
        @mixin outlineButton var(--blackLighten10Color), var(--blackLighten50Color);
    }

    &.Variant--secondary {
        @mixin outlineButton var(--blackLighten10Color), var(--support01HKBaseColor), var(--support01HKDarken20Color);
    }

    &.Variant--tertiary {
        @mixin outlineButton var(--whiteBaseColor), var(--blackLighten20Color);
    }

    &.Variant--red {
        @mixin outlineButton var(--blackLighten10Color), var(--primaryHKLighten60Color), var(--primaryHKBaseColor);
    }

    &.Variant--legacyPrimary {
        @mixin outlineButton var(--support03HKBaseColor), var(--support03HKLighten60Color);
    }
}

.Variant--clear {
    @mixin resetButtonStyles;
}

.Variant--xSmall {
    @mixin xSmallButton;
}

.Variant--responsiveFromXSmallToSmall {
    @mixin xSmallButton;

    @media (--tablet) {
        @mixin smallButton;
    }
}

.Variant--small {
    @mixin smallButton;
}

.Variant--responsiveFromSmallToMedium {
    @media (--mobileOnly) {
        @mixin smallButton;
    }
}

.Variant--responsiveFromSmallToLarge {
    @media (--mobileOnly) {
        @mixin smallButton;
    }

    @media (--tablet) {
        @mixin largeButton;
    }
}

.Variant--responsiveFromMediumToSmall {
    @media (--tablet) {
        @mixin smallButton;
    }
}

.Variant--responsiveFromMediumToLarge {
    @media (--tablet) {
        @mixin largeButton;
    }
}

.Variant--responsiveFromLargeToSmall {
    @media (--mobileOnly) {
        @mixin largeButton;
    }

    @media (--tablet) {
        @mixin smallButton;
    }
}

.Variant--large {
    @mixin largeButton;
}

.Variant--medium {
    /* todo. Move here from main styles (where this variant is defined as default). */
}

.Variant--responsiveFromLargeToXLarge {
    @mixin largeButton;

    @media (--tablet) {
        @mixin xLargeButton;
    }
}

.Variant--xLarge {
    @mixin xLargeButton;
}

.Variant--rounded {
    @mixin themeRoundedCornersSmall;
}

.Variant--shadowed {
    @mixin themeBoxShadowLarge;
}
