@define-mixin cornerRounder $radius {
    border-radius: $radius;
}

@define-mixin themeRoundedCornersXSmall {
    @mixin cornerRounder 6px;
}

@define-mixin themeRoundedCornersSmall {
    @mixin cornerRounder 8px;
}

@define-mixin themeRoundedCornersMedium {
    @mixin cornerRounder 12px;
}

@define-mixin themeRoundedCornersLarge {
    @mixin cornerRounder 16px;
}

@define-mixin themeRoundedCornersXLarge {
    @mixin cornerRounder 40px;
}

@define-mixin themeBoxShadowSmall {
    box-shadow: 0 0 20px rgb(var(--blackBaseRgb) / 4%);
}

@define-mixin themeBoxShadowLarge {
    box-shadow: 0 15px 50px rgb(var(--blackBaseRgb) / 4%);
}
